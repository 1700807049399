import React from "react";
import styled from "styled-components";

import { NewIcon as BaseIcon } from "ui/NewIcon";

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 40px;
  width: 40px;
  margin-left: 5px;
  font-size: ${Math.floor(40 * 0.3)}px;
`;

const Icon = styled(BaseIcon)`
  position: absolute;
  height: inherit;
  width: inherit;
  color: #fff;
`;

const Label = styled.span`
  position: relative;
  font-size: inherit;
  font-weight: 400;
  color: #160829;
`;

export const StarIcon = ({ rating }: { rating: number }) => (
  <Container>
    <Icon icon="badge" />
    <Label>{rating.toFixed(1).replace(".", ",")}</Label>
  </Container>
);
