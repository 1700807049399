import React from "react";
import { useTranslations } from "next-intl";

import { Audiobook, LicenseChannels } from "resources/AudiotekaApi";
import { NewIcon } from "ui/NewIcon";

import { Box, LowestPrice, Price, Title } from "./components";
import { AddToCartButton } from "./buttons";
import { InfoIcon } from "./info-icon";
import type { PricesWithCurrency } from "../../../utils";

type Props = {
  audiobook: Audiobook;
  licenses: LicenseChannels;
  price: PricesWithCurrency;
};

export const PremiumProduct = ({ audiobook, licenses, price }: Props) => {
  const t = useTranslations();

  return (
    <Box>
      <Title>
        <NewIcon icon="add-to-cart" width="24" height="24" />
        {t("product.price_box.title.buy_premium")} <InfoIcon info={t("product.price_box.info.buy_only")} />
      </Title>
      <Price>
        {price.final}
        {price.final !== price.base ? (
          <Price as="span" old>
            {price.base}
          </Price>
        ) : null}
      </Price>
      <AddToCartButton audiobook={audiobook} licenses={licenses} />
      {price.lowest && price.final !== price.base ? (
        <LowestPrice>{t("product.lowest_price", { price: price.lowest.replace(/ /g, "\xa0") })}</LowestPrice>
      ) : null}
    </Box>
  );
};
