import React from "react";
import { useTranslations } from "next-intl";

import { KlubLogo } from "ui/klub-logo";

import { Box, ClubIconWrapper, Title, Trial } from "../components";
import { ClubJoinButton } from "../buttons";

export const ClubOnlyProduct = () => {
  const t = useTranslations();

  return (
    <Box>
      <Title secondary>
        {t("product.price_box.title.club_only")}
        <ClubIconWrapper>
          <KlubLogo version="two-line" width="100%" />
        </ClubIconWrapper>
      </Title>
      <ClubJoinButton />
      <Trial>{t("product.price_box.club_trial")}</Trial>
    </Box>
  );
};
