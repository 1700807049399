import React from "react";
import styled from "styled-components";

import { breakpoint } from "modules/DesignSystem/breakpoint";
import { NewIcon } from "ui/NewIcon";

const Wrapper = styled.div<{ isVisible: boolean }>`
  margin-top: 0;
  border-radius: 8px;
  padding: 16px 12px;
  background-color: #fff;
  box-shadow: ${({ isVisible }) => (isVisible ? "0 20px 20px rgba(0, 0, 0, 0.04)" : "none")};

  @media (min-width: ${breakpoint.laptop}) {
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.04);
    margin-top: 8px;
    position: relative;
    z-index: 2;
  }
`;

const Summary = styled.button`
  border: none;
  align-items: center;
  color: #33adff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  grid-gap: 4px;
  justify-content: center;
  text-align: center;
  padding: 0;
  width: 100%;
  background: transparent;
`;

const Content = styled.div<{ isVisible: boolean }>`
  display: grid;
  grid-template-rows: ${({ isVisible }) => (isVisible ? "1fr" : "0fr")};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: grid-template-rows 300ms, opacity 600ms 200ms;

  & > * {
    overflow: hidden;
  }
`;

export const Options = ({ summary, children }: { summary: string; children: React.ReactNode }) => {
  const [isVisible, setIsVisible] = React.useState(false);

  return (
    <Wrapper isVisible={isVisible}>
      <Summary
        onClick={() => {
          setIsVisible((_isVisible) => !_isVisible);
        }}
      >
        {summary}
        <NewIcon icon={isVisible ? "chevron-up" : "chevron-down"} width="14" height="14" />
      </Summary>
      <Content isVisible={isVisible}>{children}</Content>
    </Wrapper>
  );
};
