import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslations } from "next-intl";
import * as Sentry from "@sentry/nextjs";

import BaseButton, { ButtonVariant } from "modules/DesignSystem/components/Button";
import { NewIcon } from "ui/NewIcon";
import { useFavourites } from "modules/Favourites/FavouritesProvider";
import { breakpoint } from "modules/DesignSystem/breakpoint";
import { useUser } from "modules/user";
import { getProductRelations } from "resources/AudiotekaApi";

const Button = styled(BaseButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: transparent;
  color: #fff;
  text-transform: none;
  font-size: 0;

  @media (min-width: ${breakpoint.laptop}) {
    white-space: nowrap;
    background: rgba(255, 255, 255, 0.2);
    font-size: 14px;
    width: 184px;
  }
`;

const Icon = styled(NewIcon)`
  @media (min-width: ${breakpoint.laptop}) {
    margin-right: 4px;
  }
`;

export const FavouritesButton = ({ audiobookId }: { audiobookId: string }) => {
  const t = useTranslations();
  const { count, like, dislike } = useFavourites();
  const { isLoggedIn } = useUser();

  const [isFavourite, setIsFavourite] = useState(false);

  useEffect(() => {
    async function fetchProductRelations() {
      try {
        const response = await getProductRelations([audiobookId]);

        setIsFavourite(response.relations[0].favorite);
      } catch (e) {
        Sentry.captureException(`FavouritesButton | Failed to get product relations | Error: ${e}`);
      }
    }

    if (isLoggedIn) {
      fetchProductRelations();
    }
  }, [count, audiobookId, isLoggedIn]);

  return (
    <Button
      onClick={() => (isFavourite ? dislike(audiobookId) : like(audiobookId))}
      variant={ButtonVariant.transparent}
    >
      <Icon icon={isFavourite ? "heart" : "heart-border"} height="32" width="32" />
      {isFavourite ? t("product.favourites_remove") : t("product.favourites_add")}
    </Button>
  );
};
