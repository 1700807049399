import React from "react";
import { useTranslations } from "next-intl";

import { useUser } from "modules/user";
import { Audiobook } from "resources/AudiotekaApi";
import { NewIcon } from "ui/NewIcon";

import { Box, Title } from "./components";
import { InfoIcon } from "./info-icon";
import { PlayButton } from "./buttons";

export const FreeProduct = ({ audiobook }: { audiobook: Audiobook }) => {
  const t = useTranslations();
  const { isLoggedIn } = useUser();

  return (
    <Box>
      <Title>
        <NewIcon icon="headphones" width="24" height="24" />
        {t("product.price_box.title.free")}
        {!isLoggedIn && <InfoIcon info={t("product.price_box.info.free")} />}
      </Title>
      <PlayButton audiobook={audiobook} />
    </Box>
  );
};
