import React from "react";
import styled from "styled-components";
import { useTranslations } from "next-intl";

import { ButtonVariant } from "modules/DesignSystem/components/Button";
import { Audiobook, LicenseChannels } from "resources/AudiotekaApi";
import { NewIcon } from "ui/NewIcon";

import { AddToCartButton, PlayButton } from "../buttons";
import { Options } from "../options";
import { Box, Price, Title } from "../components";
import { InfoIcon } from "../info-icon";
import type { PricesWithCurrency } from "../../../../utils";

import css from "./club.module.scss";

const OptionsBox = styled.div`
  display: grid;
  gap: 8px;
`;

interface Props {
  audiobook: Audiobook;
  licenses: LicenseChannels;
  price: PricesWithCurrency;
}

export const ClubMember = ({ audiobook, licenses, price }: Props) => {
  const t = useTranslations();

  const canBuy = !!price.base;

  return (
    <>
      <Box>
        <Title>
          <NewIcon icon="credit-card" width="24" height="24" />
          {t("product.price_box.title.club_available")}
        </Title>
        <PlayButton audiobook={audiobook} variant={ButtonVariant.outlined} />
      </Box>
      {canBuy && (
        <Options summary={t("product.price_box.options.buy")}>
          <OptionsBox>
            <Title className={css.clubMember__title}>
              <NewIcon className={css.clubMember__shelfIcon} icon="shelf" width="24" height="24" />
              {t("product.price_box.title.buy_optional")}
              <InfoIcon info={t("product.price_box.info.buy_optional")} />
            </Title>
            <Price club={price.final === price.club}>
              {price.final}
              {price.final !== price.base && price.final !== price.club ? <Price old>{price.base}</Price> : null}
            </Price>
            <AddToCartButton audiobook={audiobook} licenses={licenses} />
          </OptionsBox>
        </Options>
      )}
    </>
  );
};
