import React from "react";
import { useLocale, useTranslations } from "next-intl";

import Spinner from "modules/DesignSystem/components/Spinner";
import { ButtonVariant } from "modules/DesignSystem/components/Button";
import { usePlayer } from "modules/player";
import { useCart } from "modules/cart/cart.hooks";
import { Audiobook, LicenseChannels } from "resources/AudiotekaApi";
import { NewIcon } from "ui/NewIcon";

import { ActionButton } from "./components";

export const ClubJoinButton = () => {
  const t = useTranslations();
  const locale = useLocale();

  const isDeOrLt = locale === "de" || locale === "lt";

  return (
    <ActionButton color="#d518ff" href={`https://pages.audioteka.com/[lang]/audioteka-${isDeOrLt ? "c" : "k"}lub/`}>
      {t("product.price_box.cta.club_join")}
    </ActionButton>
  );
};

export const AddToCartButton = ({ audiobook, licenses }: { audiobook: Audiobook; licenses: LicenseChannels }) => {
  const t = useTranslations();
  const cart = useCart();

  const addToCart = () => {
    cart.addToCart(audiobook, licenses);
  };

  return (
    <ActionButton onClick={addToCart}>
      {cart.isLoading ? <Spinner size={24} /> : t("product.price_box.cta.buy")}
    </ActionButton>
  );
};

export const PlayButton = ({ audiobook, variant }: { audiobook: Audiobook; variant?: ButtonVariant }) => {
  const t = useTranslations();
  const player = usePlayer();

  const handlePlay = async () => {
    if (player.audiobook?.id !== audiobook.id) {
      player.load(audiobook.id, { autoPlay: true });
    } else {
      player.play();
    }
  };

  return (
    <ActionButton variant={variant} onClick={handlePlay}>
      <NewIcon icon="play" width="20" height="20" />
      {t("product.price_box.cta.play")}
    </ActionButton>
  );
};
