import React, { ComponentProps } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { useLocale, useTranslations } from "next-intl";
import * as Sentry from "@sentry/nextjs";
import { JSONSchema7Object } from "json-schema";

import { buildLink } from "utils/buildLink";
import { isId, parsePrice } from "utils/string";
import { cutText } from "modules/Audiobook/utils";
import { slugs } from "modules/Audiobook/slugs";
import { Audiobook } from "modules/Audiobook";
import { Breadcrumbs } from "ui/breadcrumbs";
import { getLocationInfo } from "utils/getLocationInfo";
import { Audiobook as AudiobookType, getAudiobook } from "resources/AudiotekaApi";

interface Props extends ComponentProps<typeof Audiobook> {
  breadcrumbs: ComponentProps<typeof Breadcrumbs>["items"];
}

const getNames = (audiobook: AudiobookType, key: "author" | "publisher" | "lector") =>
  audiobook._embedded[`app:${key}`].map((data) => data.name).join(", ");

const getPeople = (audiobook: AudiobookType, key: "author" | "lector") =>
  audiobook._embedded[`app:${key}`].map((data) => ({ "@type": "Person", name: data.name }));

const getBrands = (audiobook: AudiobookType) =>
  audiobook._embedded["app:publisher"].map((data) => ({ "@type": "Brand", name: data.name }));

const getReviews = (audiobook: AudiobookType) =>
  audiobook._embedded["app:user-product-review"].map((data) => {
    const review = data._embedded["app:review"];
    const rating = data._embedded["app:rating"];

    return {
      "@type": "Review",
      author: {
        "@type": "Person",
        name: review.author_signature,
      },
      datePublished: new Date(review.reviewed_at).toISOString().split("T")[0],
      reviewBody: review.review,
      reviewRating: {
        "@type": "Rating",
        ratingValue: rating.value,
        worstRating: 1,
        bestRating: 5,
      },
    };
  });

const convertMinutesToISO = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `PT${hours}H${remainingMinutes}M`;
};

export default function AudiobookPage({ audiobook, breadcrumbs, currency, darkMode, linkReplace }: Props) {
  const t = useTranslations();
  const { asPath } = useRouter();
  const locale = useLocale();

  const authors = getNames(audiobook, "author");
  const publishers = getNames(audiobook, "publisher");

  const schema: JSONSchema7Object = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: audiobook.name,
    description: t("meta.product.description", {
      product: audiobook.name,
      author: authors,
      publisher: publishers,
    }),
    genre: audiobook._embedded["app:category"][0]?.name ?? "",
    inLanguage: locale,
    bookFormat: "AudiobookFormat",
    encodingFormat: "audio/mpeg",
    duration: convertMinutesToISO(audiobook.duration),
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: audiobook.rating || 0,
      ratingCount: audiobook.rating_count,
      worstRating: 1,
      bestRating: 5,
    },
    offers: {
      "@type": "AggregateOffer",
      lowPrice: parsePrice(audiobook.price_for_subscribers || audiobook.price || "0"),
      highPrice: parsePrice(audiobook.price || "0"),
      priceCurrency: currency,
      url: `${process.env.SITE_URL}${asPath}`,
      availability: audiobook._embedded["app:context"].is_enabled
        ? "https://schema.org/InStock"
        : "https://schema.org/Outofstock",
      seller: {
        "@type": "Organization",
        name: "Audioteka",
        url: `https://audioteka.com/${locale}/`,
      },
    },
    brand: getBrands(audiobook),
    author: getPeople(audiobook, "author"),
    readBy: getPeople(audiobook, "lector"),
    review: getReviews(audiobook),
  };

  return (
    <>
      <Head>
        <title>{t("meta.product.title", { product: audiobook.name, author: authors, publisher: publishers })}</title>
        <meta
          name="description"
          content={t("meta.product.description", {
            product: audiobook.name,
            author: authors,
            publisher: publishers,
          })}
        />
        <meta name="keywords" content={authors} />

        <meta property="og:url" content={`${process.env.SITE_URL}${asPath}`} />
        <meta key="og-title" property="og:title" content={`${audiobook.name} - ${authors} | Audioteka`} />
        <meta key="og-description" property="og:description" content={cutText(audiobook.description || "", 50)} />
        <meta key="og-image" property="og:image" content={`${audiobook.image_url}?auto=format&w=500`} />
        <meta property="og:site_name" content="Audioteka" />
        <meta property="og:type" content="Audiobook" />

        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} />
      </Head>
      <Breadcrumbs items={breadcrumbs} />
      <Audiobook audiobook={audiobook} currency={currency} darkMode={darkMode} linkReplace={linkReplace} />
    </>
  );
}

AudiobookPage.layoutConfig = {
  catalogData: true,
};

export const getServerSideProps = async ({ query }) => {
  const redirectableLocales = ["cz", "de", "lt", "sk"] as const;
  const shouldBeRedirected = redirectableLocales.some((locale) => query.locale === locale && slugs[locale][query.id]);

  if (shouldBeRedirected) {
    return {
      redirect: {
        permanent: true,
        destination: buildLink("audiobook", query.locale, { id: slugs[query.locale][query.id] }),
      },
    };
  }

  const notStrict = query["not-strict"] === "true";
  let apiQueryId = query.id;

  if (!isId(apiQueryId)) {
    // Reference ID always starts with language and underscore.
    apiQueryId = /^[a-z]{2}_/i.test(apiQueryId) ? `reference-id/${apiQueryId}` : `slug/${apiQueryId}`;
  }

  try {
    const { catalogId, currency } = getLocationInfo(query.locale);
    const audiobook = await getAudiobook(apiQueryId, catalogId, notStrict);

    if (!audiobook || !audiobook.id) {
      return {
        notFound: true,
        props: {},
      };
    }

    const productId = audiobook.slug || audiobook.id;

    if (query.id !== productId) {
      return {
        redirect: {
          permanent: true,
          destination: buildLink("audiobook", query.locale, { id: productId }),
        },
      };
    }

    return {
      props: {
        audiobook,
        darkMode: false,
        currency,
        _robots: notStrict ? "noindex, nofollow" : "",
      },
    };
  } catch (error) {
    if (error.response?.status === 404) {
      return {
        notFound: true,
        props: {},
      };
    }

    Sentry.captureException(error);
    throw error;
  }
};
