import React, { useState } from "react";
import { useLocale, useTranslations } from "next-intl";
import classNames from "classnames";

import Title from "modules/DesignSystem/components/Title";
import { Audiobook } from "resources/AudiotekaApi";
import { ButtonPrimitive } from "ui/button/button-primitive";

import css from "./description.module.scss";

export function Description({ audiobook }: { audiobook: Audiobook }) {
  const t = useTranslations();
  const locale = useLocale();

  const [isExtended, setIsExtended] = useState(false);

  const isClubPriceDescriptionVisible = locale === "pl";

  return (
    <>
      <Title first>{t("product.description")}</Title>
      <article
        className={classNames(css.description, { [css.description__extended]: isExtended })}
        dangerouslySetInnerHTML={{ __html: audiobook.description_html }}
      />
      <ButtonPrimitive
        onClick={() => setIsExtended((extended) => !extended)}
        className={classNames(css.extendButton, {
          [css.extendButton__extended]: isExtended,
        })}
      >
        {isExtended ? t("product.description_less") : t("product.description_more")}
      </ButtonPrimitive>
      {isClubPriceDescriptionVisible && <p>{t("product.description_club_price")}</p>}
    </>
  );
}

export default Description;
