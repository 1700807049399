import React, { useMemo } from "react";

import { NewIcon } from "ui/NewIcon";
import { ProductReview } from "resources/AudiotekaApi";
import { leadingZero } from "utils/string";

import { Review, ReviewDate, Root, Signature, Stars } from "./styled";

interface Props {
  accent?: boolean;
  reviewItem: ProductReview;
}

const ReviewItem = ({ accent, reviewItem }: Props) => {
  const rating = reviewItem._embedded["app:rating"];
  const review = reviewItem._embedded["app:review"];

  const date = useMemo(() => {
    if (!review?.reviewed_at) {
      return "";
    }

    const _date: Date = new Date(review.reviewed_at);

    return `${leadingZero(_date.getDate(), 2)}.${leadingZero(_date.getMonth() + 1, 2)}.${_date.getFullYear()}`;
  }, [review]);

  const stars = useMemo(() => {
    const arr = [];

    for (let i = 1; i <= 5; i += 1) {
      const key = `star-${rating?.id}-${i}`;

      if (rating?.value >= i) {
        arr.push(<NewIcon key={key} icon="star" width="14" height="14" />);
      } else if (Math.ceil(rating?.value) === i) {
        arr.push(<NewIcon key={key} icon="star-half" width="14" height="14" />);
      } else {
        arr.push(<NewIcon key={key} icon="star-empty" width="14" height="14" />);
      }
    }

    return arr;
  }, [rating]);

  return (
    <Root accent={accent}>
      <Stars>{stars}</Stars>
      <ReviewDate accent={accent}>{date}</ReviewDate>
      <Review>{review?.review}</Review>
      <Signature accent={accent}>{review?.author_signature}</Signature>
    </Root>
  );
};

export default ReviewItem;
