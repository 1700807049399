import { useEffect } from "react";
import { useRouter } from "next/router";
import gtm from "react-gtm-module";

import { getGTMProduct, getListData } from "utils/products";
import { useUser } from "modules/user";
import { Audiobook } from "resources/AudiotekaApi";

import { getPrices } from "./utils";

export const useAnalytics = (audiobook: Audiobook, currency: string) => {
  const {
    query: { locale },
  } = useRouter();
  const { isLoading: isLoadingUser, isClubMember } = useUser();

  useEffect(() => {
    if (isLoadingUser) {
      return;
    }

    const prices = getPrices(audiobook, undefined, isClubMember);
    const listData = getListData(audiobook.id);

    gtm.dataLayer({
      dataLayer: {
        event: "view_item",
        ecommerce: {
          currency,
          value: prices.final,
          items: [
            getGTMProduct({
              product: audiobook,
              audiobook,
              ...listData,
            }),
          ],
        },
      },
    });
  }, [locale, audiobook, isLoadingUser, isClubMember]);
};
